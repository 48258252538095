import React from "react"
import { graphql } from "gatsby"
import AboutPage from "../../templates/about-page"

const About = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  return <AboutPage {...frontmatter} />
}

export const aboutEnPageQuery = graphql`
  query AboutEnPage {
    markdownRemark(fields: { slug: { eq: "/about/" }, langKey: { eq: "en" } }) {
      frontmatter {
        title
        description
        introMD
        quote {
          author
          content
        }
        secondIntroMD
        missionIntroMD
        careerIntroMD
        statements {
          description
          imageAlt
          title
          image {
            childImageSharp {
              gatsbyImageData(height: 300, placeholder: BLURRED)
            }
          }
        }
        career {
          description
          imageAlt
          title
          buttonText
          datasheet {
            publicURL
          }
          image {
            childImageSharp {
              gatsbyImageData(height: 300, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`

export default About
