import React from "react"
import LocalizedLink from "./localized-link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import PropTypes from "prop-types"
import PreviewCompatibleImage from "./preview-compatible-image"
import clsx from "clsx"

const LeftRightSections = ({
  title,
  description,
  image,
  imageAlt,
  buttonLink,
  buttonText,
  odd,
  renderLink,
}) => (
  <div
    className={clsx(
      !!odd && "md:space-x-reverse",
      "flex flex-col items-baseline space-y-4 md:items-center md:space-x-14 md:flex-row"
    )}
    key={title}
  >
    {!!image && (
      <div
        className={clsx(
          "flex justify-center md:w-1/2",
          !odd ? "md:justify-end" : "md:justify-start"
        )}
      >
        <PreviewCompatibleImage
          image={image}
          alt={imageAlt}
          imgClassName={`${!odd ? "md:order-first" : "md:order-last"}`}
        />
      </div>
    )}
    <div
      className={`flex flex-col md:w-1/2 ${
        !odd ? "md:order-last" : "md:order-first"
      }`}
    >
      <h1>{title}</h1>
      <p className="my-3">{description}</p>
      {!!renderLink
        ? <hr /> && renderLink()
        : !!buttonLink &&
          !!buttonText && (
            <LocalizedLink
              to={buttonLink}
              className="inline-flex items-center btn-secondary pl-0"
            >
              {buttonText}
              <FontAwesomeIcon className="ml-1" icon={faArrowRight} />
            </LocalizedLink>
          )}
    </div>
  </div>
)

LeftRightSections.defaultProps = {
  odd: false,
  renderLink: null,
  title: "",
  description: "",
}

LeftRightSections.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  imageAlt: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  odd: PropTypes.bool,
  renderLink: PropTypes.func,
}

export default LeftRightSections
