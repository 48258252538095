import React from "react"
import PropTypes from "prop-types"

const Quote = ({ quote }) => {
  return !quote ? null : (
    <div className="container mx-auto px-10 py-5 md:px-20 md:py-14">
      <p className="text-gray-50 font-medium text-lg text-center md:leading-9 md:text-2xl">
        {quote.content || ""}
      </p>
      <p className="text-gray-50 text-center font-medium text-lg mt-8 mb-0">
        {quote.author || ""}
      </p>
    </div>
  )
}

Quote.propTypes = {
  quote: PropTypes.shape({
    content: PropTypes.string,
    author: PropTypes.string,
  }),
}

export default Quote
