import React, { useCallback } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { HTMLContent } from "../components/content"
import PropTypes from "prop-types"
import FancyUnderline from "../components/fancy-underline"
import LeftRightSections from "../components/left-right-sections"
import Quote from "../components/quote"
import MarkdownIntroSection from "../components/markdown-intro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

const TitleSection = ({ title }) => {
  return (
    <section className="container mx-auto mt-4 px-5 text-center">
      <div className="inline-block relative pb-2">
        <p className="text-4xl font-extralight">{title}</p>
        <FancyUnderline />
      </div>
    </section>
  )
}

TitleSection.defaultProps = {
  title: "",
}

TitleSection.propTypes = {
  title: PropTypes.string,
}

const QuoteSection = ({ quote }) => {
  return !quote ? null : (
    <section className="mt-8 bg-wpt-primary">
      <Quote quote={quote} />
    </section>
  )
}

QuoteSection.propTypes = {
  quote: PropTypes.shape({
    content: PropTypes.string,
    author: PropTypes.string,
  }),
}

const Divider = () => {
  return (
    <div className="container mx-auto px-5">
      <hr className="border border-wpt-grey my-8" />
    </div>
  )
}

const MissionSection = ({ statements }) => {
  const hiddenLink = useCallback(() => null, [])
  return !statements ? null : (
    <section className="flex flex-col space-y-4 container mx-auto px-5 mt-12 mb-4">
      {statements.map((statement, index) => (
        <LeftRightSections
          key={statement.title}
          description={statement.description}
          image={statement.image}
          title={statement.title}
          imageAlt={statement.imageAlt}
          renderLink={hiddenLink}
          odd={index % 2 !== 0}
        />
      ))}
    </section>
  )
}

MissionSection.propTypes = {
  statements: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      imageAlt: PropTypes.string,
      image: PropTypes.object,
    })
  ),
}

const CareerSection = ({ career }) => {
  return !career ? null : (
    <section className="flex flex-col space-y-4 container mx-auto px-5 mt-12 mb-4">
      {career.map((position, index) => (
        <LeftRightSections
          key={position.title}
          description={position.description}
          image={position.image}
          title={position.title}
          imageAlt={position.imageAlt}
          odd={index % 2 !== 0}
          renderLink={() =>
            !position.datasheet ? null : (
              <a
                href={position.datasheet.publicURL}
                target="_blank"
                rel="noreferrer"
                className="inline-flex items-center btn-secondary pl-0"
              >
                {position.buttonText}
                <FontAwesomeIcon className="ml-1" icon={faExternalLinkAlt} />
              </a>
            )
          }
        />
      ))}
    </section>
  )
}

CareerSection.propTypes = {
  career: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      datasheet: PropTypes.object,
      buttonText: PropTypes.string,
      imageAlt: PropTypes.string,
      image: PropTypes.object,
    })
  ),
}

export const AboutPageTemplate = ({
  title,
  introMD,
  secondIntroMD,
  quote,
  missionIntroMD,
  statements,
  careerIntroMD,
  career,
  preview,
}) => {
  return (
    <>
      <TitleSection title={title} />
      <MarkdownIntroSection
        html={introMD}
        contentComponent={!preview && HTMLContent}
      />
      <QuoteSection quote={quote} />
      <MarkdownIntroSection
        html={secondIntroMD}
        contentComponent={!preview && HTMLContent}
      />
      {!!missionIntroMD && !!secondIntroMD && <Divider />}
      <MarkdownIntroSection
        html={missionIntroMD}
        contentComponent={!preview && HTMLContent}
      />
      <MissionSection statements={statements} />
      {Array.isArray(career) && career.length > 0 && (
        <>
          <div id="career" className="pt-10 -mb-5" />
          <Divider />
          <MarkdownIntroSection
            html={careerIntroMD}
            contentComponent={!preview && HTMLContent}
          />
          <CareerSection career={career} />
        </>
      )}
    </>
  )
}

const AboutPage = props => {
  return (
    <Layout>
      <Seo title={props.title} description={props.description} />
      <AboutPageTemplate {...props} title={props.title} />
    </Layout>
  )
}

export default AboutPage
